import React from 'react';
import { Icon, Button } from '@blueprintjs/core';
export function ZoomCanvas(editor) {
  const { Canvaseditor } = editor;

  // zoom In.
  const zoomIn = () => {
    Canvaseditor.setZoom(Canvaseditor.getZoom() * 1.1);
    Canvaseditor.setHeight(Canvaseditor.getHeight() * 1.1);
    Canvaseditor.setWidth(Canvaseditor.getWidth() * 1.1);
    Canvaseditor.calcOffset();
  };

  // zoom In.
  const zoomOut = () => {
    Canvaseditor.setZoom(Canvaseditor.getZoom() / 1.1);
    Canvaseditor.setHeight(Canvaseditor.getHeight() / 1.1);
    Canvaseditor.setWidth(Canvaseditor.getWidth() / 1.1);
    Canvaseditor.calcOffset();
  };

  return (
    <>
      <Button onClick={zoomOut} id="zoom-out" icon={<Icon icon="zoom-out" />} />
      <Button onClick={zoomIn} id="zoom-in" icon={<Icon icon="zoom-in" />} />
    </>
  );
}
