import { Icon, Button } from "@blueprintjs/core";
import React, { useState } from "react";
import { APIURL } from "../../config";
import axios from "axios";

export function SaveContinue(editor) {
  const [saveloader, setsaveloader] = useState(false);
  const {
    createID, 
    updateID, 
    canvases, 
    canvasObjects 
  } = editor;

  // Save & Download.
  const saveTemplate = () => {

    setsaveloader(true);
    let allPagesObjects = [];
    const save_api = `${APIURL}/api/v1/save`;
    
    if (canvasObjects.length > 0 ){
      // Set canvas object for all pages.
      canvasObjects.forEach((allPageObjects, pageIndex) => {
        
        canvases[pageIndex].discardActiveObject();
        canvases[pageIndex].renderAll();
        
        const canvasImg =
          canvases[pageIndex]
            ? canvases[pageIndex].toDataURL("image/png")
            : "";
        const canvasObj =
          canvases[pageIndex]
            ? escape(
                JSON.stringify(
                  canvases[pageIndex].toDatalessJSON(["customType"])
                )
              )
            : "";
        const pagesObjects = {
          page_id: allPageObjects.page_id,
          page_order: allPageObjects.page_order,
          jasondata: canvasObj,
          template_id: updateID || createID,
          images: canvasImg,
        };
        allPagesObjects.push(pagesObjects);
      });

      if (createID) {
        axios
          .post(save_api, {
            page_details: allPagesObjects,
          })
          .then(function (response) {
            setsaveloader(false);
            if (response.data.msg) {
              window.location.href = `${APIURL}/templates`;
            } else {
              alert(response.data.error);
            }
          })
          .catch(function (error) {
            setsaveloader(false);
            console.log(error);
            alert("Oops!something went wrong during save.");
          });
      } else {
        axios
          .put(save_api, {
            page_details: allPagesObjects,
          })
          .then(function (response) {
            setsaveloader(false);
            if (response.data.msg) {
              window.location.href = `${APIURL}/templates`;
            } else {
              alert(response.data.error);
            }
          })
          .catch(function (error) {
            setsaveloader(false);
            console.log(error);
            alert("Oops!something went wrong during save.");
          });
      }
    }

  };
  return (
    <div className="bp4-navbar-group bp4-align-right">
      {saveloader && (
        <Button icon={<Icon icon="download" />} text="Loading..." />
      )}
      {!saveloader && (
        <Button
          onClick={saveTemplate}
          icon={<Icon icon="download" />}
          text="Save & Continue"
        />
      )}
    </div>
  );
}
