// CLEAR: Close tab event.
export const closeTab = () => {
	const elements = document.querySelectorAll(".bp4-tab-panel");
	elements.forEach((element) => {
		element.setAttribute("aria-hidden", "true");
	});
};

// Function to get unique values from a specific key in an array of objects
export const getUniqueValues = (array, key) => {
	const uniqueValues = new Set();
	array.forEach((obj) => {
		if (obj.hasOwnProperty(key)) {
			uniqueValues.add(obj[key]);
		}
	});
	return Array.from(uniqueValues); // Convert set to array
};

// Function to Return Object from Array of objects when condition matched
export const findObject = (array, condition) => {
	return array.find(condition);
};

// Function to Return Sorting Array Items
export const sortArray = (array, key) => {
	return array.sort((a, b) => a[key] - b[key]);
};
