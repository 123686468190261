import { Button, Icon, MenuItem } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Select2 } from "@blueprintjs/select";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIURL } from "../../config";
import { sortArray } from "../../utils/helper";

export function Templates(editor) {
	const { setLoader, settemplateID, templateID } = editor;
	const [categories, setCategories] = useState([]);
	const [tags, setTags] = useState([]);
	const [templates, settemplates] = useState([]);
	const { createID, updateID, loadCategories } = useParams();
	const [selectedOption, setSelectedOption] = useState("");
	const [item, setItem] = useState("");
	const [items, setItems] = useState([]);
	const [clickedItem, setClickedItem] = useState(null);

	// CLEAR: Main Call for Categories to Fetch All Templates.
	useEffect(() => {
		// Set Loader.
		setLoader(true);

		// APIS_CALL: Fetch Categories
		let get_categories = "";
		if (loadCategories) {
			get_categories = `${APIURL}/api/v1/categories/${loadCategories}`;
		} else {
			get_categories = `${APIURL}/api/v1/categories`;
		}

		axios
			.get(get_categories)
			.then(function (response) {
				if (response.data.data.length > 0) {
					// Set category dropdown.
					setCategories(response.data.data[0]);
					// Set first category in dropdown.
					setSelectedOption(response.data.data[0].cat_slug);

					// APIS_CALL: Load template of first category.
					const get_category = `${APIURL}/api/v1/category`;

					axios
						.post(get_category, {
							cat_slug: response.data.data[0].cat_slug,
						})
						.then(function (response) {
							/* Filter Option to check incase Data is not null */
							const result = response.data.data.filter(function (el) {
								return el.jasondata !== null;
							});

							if (response.data.tags_details.length > 0) {
								setTags(sortArray(response.data.tags_details, "tag_id"));
							}
							// Set templates.
							settemplates(result);

							// Hide Loader.
							setLoader(false);
						})
						.catch(function (error) {
							// Hide Loader.
							setLoader(false);
							console.log(error);
						});
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	// Tag Change Event.
	const handleTagChange = (item) => {
		setClickedItem(item.tag_name);

		setLoader(true);

		const getCategoryViaTag = `${APIURL}/api/v1/tag`;

		axios
			.post(getCategoryViaTag, {
				tag_id: item.tag_id,
			})
			.then(function (response) {
				const result = response.data.data.filter(function (el) {
					return el.jasondata !== null;
				});

				// InProgress:- DATA is passing to Load Templates but Canvas Editor is not present.
				settemplates(result);
				// Hide Loader.
				setLoader(false);
			})
			.catch(function (error) {
				// Hide Loader.
				setLoader(false);
				console.log(error);
			});
	};

	const handleSelectItem = (selectedItem, evt) => {
		if (evt.key === "Enter") {
			handleTagChange(selectedItem);
		}
	};

	const filterTags = (query, items, _index, exactMatch) => {
		const normalizedTitle = items.tag_name.toLowerCase();
		const normalizedQuery = query.toLowerCase();

		if (exactMatch) {
			return normalizedTitle === normalizedQuery;
		} else {
			return (
				`${items.tag_name}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0
			);
		}
	};

	const renderTag = (item, { modifiers }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}

		return (
			<MenuItem
				active={modifiers.active}
				key={item.tag_id}
				text={
					<>
						{clickedItem === item.tag_name ? (
							<Icon icon='tick' />
						) : (
							<Icon icon='' />
						)}
						{item.tag_name}
					</>
				}
				onClick={(elm) => {
					handleTagChange(item);
					setItems((items) => {
						return [...items, elm.target.textContent];
					});
				}}
			/>
		);
	};

	return (
		<div style={{ height: "100%", overflow: "auto" }}>
			<div className='selectDropdown'>
				<Select2
					items={tags}
					disabled={createID || updateID}
					itemPredicate={filterTags}
					itemRenderer={renderTag}
					onItemSelect={handleSelectItem}
					resetOnClose={true}
					noResults={<MenuItem disabled={true} text='No results.' />}
				>
					{clickedItem ? (
						<Button rightIcon='filter-keep' intent='primary' />
					) : (
						<Button rightIcon='filter-list' />
					)}
				</Select2>
				<select
					id='categoryName'
					className='form-select'
					disabled={createID || updateID}
					value={selectedOption}
				>
					<option value=''>Please select one</option>
					{categories && (
						<option value={categories.cat_slug}>{categories.cat_name}</option>
					)}
				</select>
			</div>
			{ !createID && !updateID && (		
				<div className='template-row'>
					{templates.length > 0 &&
						templates.map(function (templateItem, index) {
							return (
							<div className="template-column" key={index}>
							<img
								src={templateItem.images}
								alt="templates"
								onClick={() => settemplateID(templateItem.template_id)
								}
							/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
