import { Button, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import React from "react";

export function Layer(editor) {
	const { Canvaseditor, disabledObj } = editor;
	let objects = Canvaseditor.getObjects();

	const logObjectOrder = () => {
		console.log("Current Object Order:");
		console.log(Canvaseditor.getObjects());
		Canvaseditor.getObjects().forEach(function (obj, index) {
			console.log("Object " + (index + 1) + ": " + obj.type);
		});
	};

	const moveObjectUp = () => {
		let selectedObj = Canvaseditor.getActiveObject();
		if (selectedObj) {
			Canvaseditor.bringForward(selectedObj);
			Canvaseditor.renderAll();
			// logObjectOrder();
		}
	};

	const moveObjectDown = () => {
		let selectedObj = Canvaseditor.getActiveObject();
		if (selectedObj) {
			Canvaseditor.sendBackwards(selectedObj);
			Canvaseditor.renderAll();
			// logObjectOrder();
		}
	};

	const moveObjectForward = () => {
		let selectedObj = Canvaseditor.getActiveObject();
		if (selectedObj) {
			selectedObj.bringToFront();
			Canvaseditor.renderAll();
			// logObjectOrder();
		}
	};

	const moveObjectBottom = () => {
		let selectedObj = Canvaseditor.getActiveObject();
		if (selectedObj) {
			selectedObj.sendToBack();
			Canvaseditor.renderAll();
			// logObjectOrder();
		}
	};

	return (
		<div className='position'>
			<Popover2
				interactionKind='click'
				popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
				placement='bottom'
				content={
					<Menu>
						<MenuItem onClick={moveObjectUp} icon='arrow-up' text='Move Up' />
						<MenuItem
							onClick={moveObjectDown}
							icon='arrow-down'
							text='Move Down'
						/>
						<MenuItem
							onClick={moveObjectForward}
							icon='double-chevron-up'
							text='Move to Forward'
						/>
						<MenuItem
							onClick={moveObjectBottom}
							icon='double-chevron-down'
							text='Move to Bottom'
						/>
					</Menu>
				}
				renderTarget={({ ref, ...targetProps }) => (
					<Button
						disabled={disabledObj}
						{...targetProps}
						elementRef={ref}
						icon={<Icon icon='move' />}
						text='Layers'
					/>
				)}
			/>
		</div>
	);
}
