import React from 'react';
import { Icon, Button } from '@blueprintjs/core';

export function DeleteElement(editor) {
  const { disabledObj, Canvaseditor, setdisabledObj } = editor;

  // Set delete element event.
  const handleDeleteElement = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.remove(Canvaseditor.getActiveObject());
      Canvaseditor.renderAll();
      setdisabledObj(true);
    }
  };

  return (
    <div className="deleteElement">
      <Button disabled={disabledObj} onClick={handleDeleteElement} icon={<Icon icon="trash" />} />
    </div>
  );
}
