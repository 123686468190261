import { Button } from "@blueprintjs/core";
import React, { useState } from "react";

export const Pagination = (args) => {
	const {
    setCurrentPage,
    currentPage,
    canvasObjects,
    selectedIndex,
    setSelectedIndex,
  } = args;

	/* const handleNextPage = () => {
        setCurrentPage(prevPage => (prevPage < canvasObjects.length ? prevPage + 1 : prevPage));
    };
    
    const handlePrevPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
    }; */

	const handleCurrentPage = (pageIndex) => {
		setCurrentPage(pageIndex + 1);
		setSelectedIndex(pageIndex);
	};

	return (
		<>
			{/* canvasObjects.length > 0 &&
            <>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Prev Page
                </Button>
                <span className='currentpage'>Page {currentPage}</span>
                <Button onClick={handleNextPage} disabled={currentPage === canvasObjects.length}>
                    Next Page
                </Button>
            </>
        */}
			{canvasObjects.length > 0 &&
				canvasObjects.map((item, index) => (
					<div key={index} className={selectedIndex === index ? "active" : ""}>
						<Button onClick={() => handleCurrentPage(index)}>
							{item.page_name}
						</Button>
					</div>
				))}
		</>
	);
};
