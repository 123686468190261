import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import { IdContext } from "./components/Contexts/IdContext";
import { PageNotFound } from "./pages/404";
import { Home } from "./pages/Home";

export default function App(){
	const [id, setId] = useState(null);

	return (
		<Router>
			{ /* <IdContext.Provider value={{ id, setId }}> */ }
				<Routes>
					<Route exact path='/' element={<Home />}></Route>
					<Route exact path='/:createID/create' element={<Home />}></Route>
					<Route exact path='/:updateID/update' element={<Home />}></Route>
					<Route
						exact
						path='/:sessionId/:apiUrl/:cartKey/:loadCategories/user-edit'
						element={<Home />}
					></Route>
					<Route path='/*' element={<PageNotFound />}></Route>
				</Routes>
			{ /* </IdContext.Provider> */ }
		</Router>
	);
}
