import { Button, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import React from "react";

export function Position(editor) {
	const { Canvaseditor, disabledObj } = editor;
	// Hanlde position element.
	const handleLeftAlignment = () => {
		if (Canvaseditor.getActiveObject()) {
			console.log(Canvaseditor.getActiveObject().get("type"));
			let boundBox = Canvaseditor.getActiveObject().getBoundingRect(true, true);
			let activeObject = Canvaseditor.getActiveObject();
			Canvaseditor.getActiveObject().set(
				"left",
				activeObject.left + -boundBox.left
			);
			Canvaseditor.renderAll();
		}
	};

	const handleRightAlignment = () => {
		if (Canvaseditor.getActiveObject()) {
			let boundBox = Canvaseditor.getActiveObject().getBoundingRect(true, true);
			let activeObject = Canvaseditor.getActiveObject();
			let rightValue =
				activeObject.left + Canvaseditor.width - boundBox.left - boundBox.width;
			Canvaseditor.getActiveObject().set("left", rightValue);
			Canvaseditor.renderAll();
		}
	};

	const handleCenterAlignment = () => {
		if (Canvaseditor.getActiveObject()) {
			let boundBox = Canvaseditor.getActiveObject().getBoundingRect(true, true);
			let activeObject = Canvaseditor.getActiveObject();
			let centerValue =
				activeObject.left +
				(Canvaseditor.width - boundBox.width) / 2 -
				boundBox.left;
			Canvaseditor.getActiveObject().set("left", centerValue);
			Canvaseditor.renderAll();
		}
	};

	const handleTopAlignment = () => {
		if (Canvaseditor.getActiveObject()) {
			let boundBox = Canvaseditor.getActiveObject().getBoundingRect(true, true);
			let activeObject = Canvaseditor.getActiveObject();
			let topValue = activeObject.top + -boundBox.top;
			Canvaseditor.getActiveObject().set("top", topValue);
			Canvaseditor.renderAll();
		}
	};

	const handleMiddleAlignment = () => {
		if (Canvaseditor.getActiveObject()) {
			let boundBox = Canvaseditor.getActiveObject().getBoundingRect(true, true);
			let activeObject = Canvaseditor.getActiveObject();
			let middleValue =
				activeObject.top +
				(Canvaseditor.height - boundBox.height) / 2 -
				boundBox.top;
			Canvaseditor.getActiveObject().set("top", middleValue);
			Canvaseditor.renderAll();
		}
	};

	const handleBottomAlignment = () => {
		if (Canvaseditor.getActiveObject()) {
			let boundBox = Canvaseditor.getActiveObject().getBoundingRect(true, true);
			let activeObject = Canvaseditor.getActiveObject();
			let bottomValue =
				activeObject.top + Canvaseditor.height - boundBox.top - boundBox.height;
			Canvaseditor.getActiveObject().set("top", bottomValue);
			Canvaseditor.renderAll();
		}
	};

	return (
		<div className='position'>
			<Popover2
				interactionKind='click'
				popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
				placement='bottom'
				content={
					<Menu>
						<MenuItem
							onClick={handleLeftAlignment}
							icon='alignment-left'
							text='Align left'
						/>
						<MenuItem
							onClick={handleCenterAlignment}
							icon='alignment-vertical-center'
							text='Align center'
						/>
						<MenuItem
							onClick={handleRightAlignment}
							icon='alignment-right'
							text='Align right'
						/>
						<MenuItem
							onClick={handleTopAlignment}
							icon='alignment-top'
							text='Align top'
						/>
						<MenuItem
							onClick={handleMiddleAlignment}
							icon='alignment-horizontal-center'
							text='Align middle'
						/>
						<MenuItem
							onClick={handleBottomAlignment}
							icon='alignment-bottom'
							text='Align bottom'
						/>
					</Menu>
				}
				renderTarget={({ ref, ...targetProps }) => (
					<Button
						disabled={disabledObj}
						{...targetProps}
						elementRef={ref}
						icon={<Icon icon='layers' />}
						text='Position'
					/>
				)}
			/>
		</div>
	);
}
