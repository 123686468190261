import { Icon } from "@blueprintjs/core";
import { fabric } from "fabric";
import React from "react";

export function UploadComponet(editor) {
	const {
		Canvaseditor,
		setShowToolbar,
		setelementStrokColor,
		setTextToolbar,
		setshowUploadToolbar,
		closeTab,
	} = editor;

	const uploadedImages = [];

	const uploadImage = (e) => {
		const files = e.target.files;

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const reader = new FileReader();

			reader.onload = function (e) {
				const img = new Image();
				img.onload = function () {
					const fabricImage = new fabric.Image(img, {
						left: 0,
						top: 0,
						scaleX: 0.5,
						scaleY: 0.5,
					});
					uploadedImages.push({
						src: e.target.result,
						fabricImage: fabricImage,
						width: img.width,
						height: img.height,
					});
					updateImageList();
				};
				img.src = e.target.result;
			};

			reader.readAsDataURL(file);
		}

		// Close tab event.
		const resizeWidth = window.innerWidth;
		if (resizeWidth <= 770) {
			closeTab();
		}
	};

	const updateImageList = () => {
		const imageList = document.getElementById("imageList");
		imageList.innerHTML = "";
		uploadedImages.forEach((item, index) => {
			const imgElement = document.createElement("img");
			imgElement.src = item.src;
			imgElement.loading = "lazy";
			imgElement.style.height = "auto";
			imgElement.addEventListener("click", function () {
				bindImageToCanvas(index);
				displayImageDetails(index);
			});
			imageList.appendChild(imgElement);
		});
	};

	const bindImageToCanvas = (index) => {
		const selectedImage = uploadedImages[index];
		const canvasWidth = Canvaseditor.getWidth();
		const canvasHeight = Canvaseditor.getHeight();
		const imageWidth = selectedImage.width;
		const imageHeight = selectedImage.height;

		// Calculate scale to fit the image to the canvas
		const scaleX = canvasWidth / imageWidth;
		const scaleY = canvasHeight / imageHeight;
		const scale = Math.min(scaleX, scaleY);

		selectedImage.fabricImage.set({
			scaleX: scale,
			scaleY: scale,
			left: (canvasWidth - imageWidth * scale) / 2,
			top: (canvasHeight - imageHeight * scale) / 2,
		});

		Canvaseditor.add(selectedImage.fabricImage).setActiveObject(
			selectedImage.fabricImage
		);

		selectedImage.fabricImage.on("scaling", function () {
			displayImageDetails(index);
		});

		selectedImage.fabricImage.on("selected", function () {
			displayImageDetails(index);
		});

		Canvaseditor.centerObject(selectedImage.fabricImage);
		Canvaseditor.renderAll();
		setShowToolbar(true);
		setshowUploadToolbar(true);
		setelementStrokColor(false);
		setTextToolbar(false);
	};

	const displayImageDetails = (index) => {
		const imageDetails = document.getElementById("imageDetails");
		imageDetails.innerHTML = "";

		const selectedImage = uploadedImages[index];
		const inchWidth =
			selectedImage.fabricImage.getScaledWidth() / Canvaseditor.getZoom() / 96;
		const inchHeight =
			selectedImage.fabricImage.getScaledHeight() / Canvaseditor.getZoom() / 96;
		const dpiWidth = selectedImage.width / inchWidth;
		const detailsDiv = document.createElement("div");
		detailsDiv.innerHTML = `
						<p>Width: ${inchWidth.toFixed(2)} inches</p>
						<p>Height: ${inchHeight.toFixed(2)} inches</p>
						<p>Resolution : ${dpiWidth.toFixed(2)}</p>
				`;
		imageDetails.appendChild(detailsDiv);
	};

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ height: "45px", paddingTop: "5px" }}>
				Do you want to upload your own images?
			</div>
			<div style={{ marginBottom: "20px" }}>
				<label
					htmlFor='input-file'
					className='bp4-button'
					style={{ width: "100%" }}
				>
					<Icon size='18' icon='upload' />
					<span className='bp4-button-text'>Upload Image</span>
				</label>
				<input
					onChange={uploadImage}
					type='file'
					id='input-file'
					accept='image/*'
					style={{ display: "none" }}
					multiple
				/>
			</div>
			<div id='imageDetails'></div>
			<div id='imageList'></div>
		</div>
	);
}
