import React, { useState } from "react";
import { Icon, Button } from "@blueprintjs/core";
import axios from "axios";

export function AddToCart(editor) {
  const [saveloader, setsaveloader] = useState(false);
  const {
    sessionId,
    apiUrl,
    cartKey,
    loadCategories,
    canvases,
    canvasObjects,
  } = editor;

  // Save & Download.
  const svgToSVG = () => {
    if (sessionId && apiUrl && cartKey && loadCategories) {
      if (sessionId && typeof sessionId !== "undefined") {
        setsaveloader(true);

        let allPagesObjects = [];

        if (canvasObjects.length > 0) {
          const api_url = atob(atob(apiUrl));

          const encodedData = JSON.stringify({
            session_id: sessionId,
            cart_key: cartKey,
          });

          // Set canvas object for all pages.
          canvasObjects.forEach((allPageObjects, pageIndex) => {
            canvases[pageIndex].discardActiveObject();
            canvases[pageIndex].renderAll();
            const BGColor =
              canvases[pageIndex]
                ? canvases[pageIndex].backgroundColor
                : "";
            const canvasImg =
              canvases[pageIndex]
                ? canvases[pageIndex].toDataURL("image/png")
                : "";
            const canvasObj =
              canvases[pageIndex]
                ? canvases[pageIndex].toSVG()
                : "";
            const pagesObjects = {
              bg_color: BGColor,
              image: canvasImg,
              canvas_data: canvasObj,
            };
            allPagesObjects.push(pagesObjects);
          });

          axios
            .post(api_url, {
              token: btoa(encodedData),
              canvas_size: localStorage.getItem("canvas_size"),
              page_details: allPagesObjects,
            })
            .then(function (response) {
              setsaveloader(false);
              if (response.data.data.message) {
                window.location.href = response.data.data.cart_url;
              } else {
                alert(response.data.data.error);
              }
            })
            .catch(function (error) {
              setsaveloader(false);
              console.log(error);
              alert("Oops!something went wrong during save.");
            });
        }
      } else {
        alert("Unauthorized information.");
      }
    }
  };

  return (
    <div className="bp4-navbar-group bp4-align-right">
      {saveloader && (
        <Button icon={<Icon icon="download" />} text="Loading..." />
      )}
      {!saveloader && (
        <Button
          onClick={svgToSVG}
          icon={<Icon icon="download" />}
          text="Save & Continue"
        />
      )}
    </div>
  );
}
