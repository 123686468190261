import React from 'react';
import { Icon, Button } from '@blueprintjs/core';

export function Duplicate(editor) {
  const { disabledObj, Canvaseditor } = editor;

  // Set dublicate object.
  const handleDuplicate = () => {
    if (Canvaseditor.getActiveObject()) {
      // clone again, so you can do multiple copies.
      Canvaseditor.getActiveObject().clone(function (clonedObj) {
        Canvaseditor.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true
        });
        if (clonedObj.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          // clonedObj.canvas = canvas;
          clonedObj.forEachObject(function (obj) {
            Canvaseditor.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          Canvaseditor.add(clonedObj);
        }
        Canvaseditor.setActiveObject(clonedObj);
        Canvaseditor.requestRenderAll();
      });

      Canvaseditor.renderAll();
    }
  };

  return (
    <div className="duplicate">
      <Button disabled={disabledObj} onClick={handleDuplicate} icon={<Icon icon="duplicate" />} />
    </div>
  );
}
