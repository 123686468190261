import axios from "axios";
import { fabric } from "fabric";
import { APIURL } from "../../config";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductMargin } from "../Templates/ProductMargin";
import { findObject, getUniqueValues } from "../../utils/helper";

export function CanvasArea(props) {
  const {
    currentPage,
    setCurrentPage,
    setCanvasObjects,
    canvasObjects,
    setcanvases,
    setcanvasObj,
    setLoader,
    closeTab,
    templateID,
    setShowToolbar,
    setTextToolbar,
    setelementStrokColor,
    setshowUploadToolbar,
    updateID,
    createID,
    currentPageObject,
    setSelectedIndex,
  } = props;
  // const { id } = useContext(IdContext);
  const { loadCategories } = useParams();
  const [Productmargin, setProductmargin] = useState(false);
  const [safeMargin, setsafeMargin] = useState(false);
  const [cutMargin, setcutMargin] = useState(false);
  const [reinitialize, setreinitialize] = useState(false);
  // const [show, setShow] = useState(false);
   
  // Running for Update ID and Create ID Pages.
  useEffect(() => {
    if (updateID || createID) {
      setLoader(true);

      // Set re-initialize.
      // setreinitialize(true);

      let get_templates = `${APIURL}/api/v1/template`;

      axios
        .get(get_templates, {
          params: {
            template_id: updateID || createID,
          },
        })
        .then(function (response) {
          // DONE: Collect the Common Category ID of this specific template.
          let catId = getUniqueValues(response.data.pages, "cat_id").toString();
          let canvasPages = response.data.page_data;

          // Set canvas object for pagination.
          setCanvasObjects(canvasPages);

          const getCategoriesAPI = `${APIURL}/api/v1/categories`;

          axios
            .get(getCategoriesAPI)
            .then(function (categoriesResponse) {
              if (categoriesResponse.data.data.length > 0) {
                let findCatSlug = findObject(
                  categoriesResponse.data.data,
                  (obj) => obj.id === parseInt(catId, 10)
                );

                // DONE: Collected Category Slug Name which matches to Common Category ID.
                let catSlugValue = findCatSlug.cat_slug;
                const get_category = `${APIURL}/api/v1/category`;
                const elements = [];

                axios
                  .post(get_category, {
                    cat_slug: catSlugValue,
                  })
                  .then(function (categoryResponse) {
                    let result = findObject(
                      categoryResponse.data.data,
                      (obj) =>
                        obj.template_id === parseInt(updateID || createID, 10)
                    );

                    if (result == undefined) {
                      result = categoryResponse.data.data[0];
                    }

                    // Set cut & safe margin.
                    setsafeMargin(result.safe_margin);
                    setcutMargin(result.cut_margin);

                    // DONE: Collected All Category Response to Collect Canvas Size.
                    canvasPages.forEach((pageObjects) => {
                      elements.push(pageObjects.page_details);
                    });
                    // InProgress: Divide Code from Here for Generating Canvas as above code works for http://localhost:3000/ but not working for http://localhost:3000/53/update
                    const canvasOBJ = [];
                    elements.forEach((objects, index) => {
                      const canvasObj = objects
                        ? decodeURIComponent(objects)
                        : objects;

                      const ratio = Math.min(
                        600 / result.canvas_width_px,
                        600 / result.canvas_height_px
                      );
                      const canvas = new fabric.Canvas(
                        `canvas-${index + 1}`,
                        {
                          width: result.canvas_width_px * ratio,
                          height: result.canvas_height_px * ratio,
                        },
                        { selection: false }
                      );
                      canvas.preserveObjectStacking = true;

                      // Set product margin.
                      // if (!canvasObj) {
                      setProductmargin(true);
                      // }

                      if (objects) {
                        /* canvas.loadFromJSON(canvasObj, () => {
                          canvas.renderAll();
                        }); */
                        canvas.loadFromJSON(canvasObj, () => {
                          // Callback function after JSON data is loaded.
                          canvas.getObjects().forEach(function (object) {
                            if (object instanceof fabric.Group) {
                              canvas.remove(object);
                            }
                          });

                          // Render the canvas after removing fabric.Line objects.
                          canvas.renderAll();
                        });
                      }

                      // Function to update the canvas size based on the container size (Responsive).
                      updateCanvasResposiveSize(canvas);
                      canvasOBJ.push(canvas);
                    });

                    // Set multiple canvas.
                    setcanvases(canvasOBJ);

                    if (canvasOBJ.length > 0) {
                      // Set canvas object for active page.
                      const updatedCanvases = [...canvasOBJ];
                      const activeCanvas = updatedCanvases[currentPage - 1];
                      setcanvasObj(activeCanvas);

                      // Show & hide tools options base on type.
                      activeCanvas.on("mouse:down", function () {
                        if (activeCanvas.getActiveObject()) {
                          const type = activeCanvas
                            .getActiveObject()
                            .get("type");

                          // Show toolbar.
                          setShowToolbar(true);

                          if (type === "textbox") {
                            setTextToolbar(true);
                            setelementStrokColor(false);
                            setshowUploadToolbar(false);
                          } else if (type === "image") {
                            setTextToolbar(false);
                            setelementStrokColor(false);
                            setshowUploadToolbar(true);
                          } else {
                            setTextToolbar(false);
                            setelementStrokColor(true);
                            setshowUploadToolbar(false);
                          }

                          // Unselect cut & safe margin.
                          if (type === "group") {
                            const activeObject = activeCanvas.getActiveObject();
                            activeObject.selectable = false;
                            activeObject.evented = false;
                            activeObject.hoverCursor = "default";
                            activeCanvas.discardActiveObject().renderAll();
                          }
                        }
                      });
                    }
                    // Set width & height in inch for svg to pdf in WordPress.
                    localStorage.removeItem("canvas_size");
                    localStorage.setItem(
                      "canvas_size",
                      result.canvas_width_in + "-" + result.canvas_height_in
                    );

                    // Close tab event.
                    const resizeWidth = window.innerWidth;
                    if (resizeWidth <= 770) {
                      closeTab();
                    }
                    // Hide Loader.
                    setLoader(false);
                  })
                  .catch(function (error) {
                    // Hide Loader.
                    setLoader(false);
                    console.log(error);
                  });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [updateID || createID]);

  // Running for user screen.
  useEffect(() => {
    
    if (!updateID && !createID && !templateID) {
      
      setLoader(true);

      // Set canvas empty object for pagination.
      setCanvasObjects([]);

      // Set re-initialize.
      setreinitialize(true);

      let get_categories = "";

      if (loadCategories) {
        get_categories = `${APIURL}/api/v1/categories/${loadCategories}`;
      } else {
        get_categories = `${APIURL}/api/v1/categories`;
      }
      axios.get(get_categories).then(function (response) {
        if (response.data.data.length > 0) {
          // Load template of first category.
          const get_category = `${APIURL}/api/v1/category`;
          axios
            .post(get_category, {
              cat_slug: response.data.data[0].cat_slug,
            })
            .then(function (categoryRes) {
              if (categoryRes.data.data.length > 0) {
                const categoryDetail = categoryRes.data.data[0];

                let get_templates = `${APIURL}/api/v1/template`;

                axios
                  .get(get_templates, {
                    params: {
                      template_id: categoryDetail.template_id,
                    },
                  })
                  .then(function (templateRes) {
                    if (templateRes.data.page_data.length > 0) {
                      const elements = [];

                      let canvasPages = templateRes.data.page_data;

                      // Set canvas object for pagination.
                      setCanvasObjects(canvasPages);
                      
                      // Set cut & safe margin.
                      setsafeMargin(categoryDetail.safe_margin);
                      setcutMargin(categoryDetail.cut_margin);

                      // DONE: Collected All Category Response to Collect Canvas Size.
                      canvasPages.forEach((pageObjects) => {
                        elements.push(pageObjects.page_details);
                      });

                      // InProgress: Divide Code from Here for Generating Canvas as above code works for http://localhost:3000/ but not working for http://localhost:3000/53/update
                      const canvasOBJ = [];
                      elements.forEach((objects, index) => {
                        
                        const canvasObj = objects
                          ? decodeURIComponent(objects)
                          : objects;

                        const ratio = Math.min(
                          600 / categoryDetail.canvas_width_px,
                          600 / categoryDetail.canvas_height_px
                        );
                        
                        const canvas = new fabric.Canvas(
                          `canvas-${index + 1}`,
                          {
                            width: categoryDetail.canvas_width_px * ratio,
                            height: categoryDetail.canvas_height_px * ratio,
                          },
                          { selection: false }
                        );
                        
                        canvas.preserveObjectStacking = true;

                        // Set product margin.
                        // if (!canvasObj) {
                        setProductmargin(true);
                        // }

                        if (objects) {
                          /* canvas.loadFromJSON(canvasObj, () => {
                            canvas.renderAll();
                          }); */
                          canvas.loadFromJSON(canvasObj, () => {
                            // Callback function after JSON data is loaded.
                            canvas.getObjects().forEach(function (object) {
                              if (object instanceof fabric.Group) {
                                canvas.remove(object);
                              }
                            });
                            // Render the canvas after removing fabric.Line objects.
                            canvas.renderAll();
                          });
                        }

                        // Function to update the canvas size based on the container size (Responsive).
                        updateCanvasResposiveSize(canvas);
                        canvasOBJ.push(canvas);
                      });

                      // Set multiple canvas.
                      setcanvases(canvasOBJ);

                      if (canvasOBJ.length > 0) {
                        // Set canvas object for active page.
                        const updatedCanvases = [...canvasOBJ];
                        const activeCanvas = updatedCanvases[currentPage - 1];
                        setcanvasObj(activeCanvas);

                        // Show & hide tools options base on type.
                        activeCanvas.on("mouse:down", function () {
                          if (activeCanvas.getActiveObject()) {
                            const type = activeCanvas
                              .getActiveObject()
                              .get("type");

                            // Show toolbar.
                            setShowToolbar(true);

                            if (type === "textbox") {
                              setTextToolbar(true);
                              setelementStrokColor(false);
                              setshowUploadToolbar(false);
                            } else if (type === "image") {
                              setTextToolbar(false);
                              setelementStrokColor(false);
                              setshowUploadToolbar(true);
                            } else {
                              setTextToolbar(false);
                              setelementStrokColor(true);
                              setshowUploadToolbar(false);
                            }

                            // Unselect cut & safe margin.
                            if (type === "group") {
                              const activeObject =
                                activeCanvas.getActiveObject();
                              activeObject.selectable = false;
                              activeObject.evented = false;
                              activeObject.hoverCursor = "default";
                              activeCanvas.discardActiveObject().renderAll();
                            }
                          }
                        });
                      }
                      // Set width & height in inch for svg to pdf in WordPress.
                      localStorage.removeItem("canvas_size");
                      localStorage.setItem(
                        "canvas_size",
                        categoryDetail.canvas_width_in +
                          "-" +
                          categoryDetail.canvas_height_in
                      );

                      // Close tab event.
                      const resizeWidth = window.innerWidth;
                      if (resizeWidth <= 770) {
                        closeTab();
                      }
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }
              // Hide Loader.
              setLoader(false);
            })
            .catch(function (error) {
              // Hide Loader.
              setLoader(false);
              console.log(error);
            });
        }
      });
    }
  }, [reinitialize]);
  
  // Load canvas area when click on template
  useEffect(() => {
    if (templateID) {
      
      setLoader(true);
      
      // Set canvas empty object for pagination.
      setCanvasObjects([]);
      
      let get_templates = `${APIURL}/api/v1/template`;
      axios
        .get(get_templates, {
          params: {
            template_id: templateID,
          },
        })
        .then(function (response) {
          // DONE: Collect the Common Category ID of this specific template.
          let catId = getUniqueValues(response.data.pages, "cat_id").toString();
          let canvasPages = response.data.page_data;
          const getCategoriesAPI = `${APIURL}/api/v1/categories`;

          // Set canvas object for pagination.
          setCanvasObjects(canvasPages);

          // Set reinitial & set first page of canvas.
          setCurrentPage(1);
          setSelectedIndex(0);

          axios
            .get(getCategoriesAPI)
            .then(function (categoriesResponse) {
              if (categoriesResponse.data.data.length > 0) {
                let findCatSlug = findObject(
                  categoriesResponse.data.data,
                  (obj) => obj.id === parseInt(catId, 10)
                );

                // DONE: Collected Category Slug Name which matches to Common Category ID.
                let catSlugValue = findCatSlug.cat_slug;
                const get_category = `${APIURL}/api/v1/category`;
                const elements = [];

                axios
                  .post(get_category, {
                    cat_slug: catSlugValue,
                  })
                  .then(function (categoryResponse) {
                    let result = findObject(
                      categoryResponse.data.data,
                      (obj) => obj.template_id === parseInt(templateID, 10)
                    );

                    if (result == undefined) {
                      result = categoryResponse.data.data[0];
                    }

                    // Set cut & safe margin.
                    setsafeMargin(result.safe_margin);
                    setcutMargin(result.cut_margin);

                    // DONE: Collected All Category Response to Collect Canvas Size.
                    canvasPages.forEach((pageObjects) => {
                      elements.push(pageObjects.page_details);
                    });

                    // InProgress: Divide Code from Here for Generating Canvas as above code works for http://localhost:3000/ but not working for http://localhost:3000/53/update
                    const canvasOBJ = [];
                    elements.forEach((objects, index) => {
                      const canvasObj = objects
                        ? decodeURIComponent(objects)
                        : objects;

                      const ratio = Math.min(
                        600 / result.canvas_width_px,
                        600 / result.canvas_height_px
                      );

                      const canvas = new fabric.Canvas(
                        `canvas-${index + 1}`,
                        {
                          width: result.canvas_width_px * ratio,
                          height: result.canvas_height_px * ratio,
                        },
                        { selection: false }
                      );

                      canvas.preserveObjectStacking = true;

                      // Set product margin.
                      setProductmargin(true);

                      if (objects) {
                        canvas.loadFromJSON(canvasObj, () => {
                          // Callback function after JSON data is loaded.
                          canvas.getObjects().forEach(function (object) {
                            if (object instanceof fabric.Group) {
                              canvas.remove(object);
                            }
                          });

                          // Render the canvas after removing fabric.Line objects.
                          canvas.renderAll();
                        });
                      }

                      // Function to update the canvas size based on the container size (Responsive).
                      updateCanvasResposiveSize(canvas);
                      canvasOBJ.push(canvas);
                    });

                    // Set multiple canvas.
                    setcanvases(canvasOBJ);

                    if (canvasOBJ.length > 0) {
                      // Set canvas object for active page.
                      const updatedCanvases = [...canvasOBJ];
                      const activeCanvas = updatedCanvases[0];

                      setcanvasObj(activeCanvas);

                      // Show & hide tools options base on type.
                      activeCanvas.on("mouse:down", function () {
                        if (activeCanvas.getActiveObject()) {
                          const type = activeCanvas
                            .getActiveObject()
                            .get("type");

                          // Show toolbar.
                          setShowToolbar(true);

                          if (type === "textbox") {
                            setTextToolbar(true);
                            setelementStrokColor(false);
                            setshowUploadToolbar(false);
                          } else if (type === "image") {
                            setTextToolbar(false);
                            setelementStrokColor(false);
                            setshowUploadToolbar(true);
                          } else {
                            setTextToolbar(false);
                            setelementStrokColor(true);
                            setshowUploadToolbar(false);
                          }

                          // Unselect cut & safe margin.
                          if (type === "group") {
                            const activeObject = activeCanvas.getActiveObject();
                            activeObject.selectable = false;
                            activeObject.evented = false;
                            activeObject.hoverCursor = "default";
                            activeCanvas.discardActiveObject().renderAll();
                          }
                        }
                      });
                    }
                    // Set width & height in inch for svg to pdf in WordPress.
                    localStorage.removeItem("canvas_size");
                    localStorage.setItem(
                      "canvas_size",
                      result.canvas_width_in + "-" + result.canvas_height_in
                    );

                    // Close tab event.
                    const resizeWidth = window.innerWidth;
                    if (resizeWidth <= 770) {
                      closeTab();
                    }

                    // Hide Loader.
                    setLoader(false);
                  })
                  .catch(function (error) {
                    // Hide Loader.
                    setLoader(false);
                    console.log(error);
                  });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [templateID]);

  // Function to update the canvas size based on the container size (Responsive).
  const updateCanvasResposiveSize = (cavasObj) => {
    const resizeWidth = window.innerWidth;
    if (cavasObj.contextContainer && resizeWidth <= 770) {
      const newWidth = window.innerWidth <= 1000 ? window.innerWidth : 600;
      if (cavasObj.width != newWidth) {
        const scaleX = newWidth / cavasObj.width;
        const canvasWidth = cavasObj.getWidth() * scaleX;
        const canvasHeight = cavasObj.getHeight() * scaleX;
        const objects = cavasObj.getObjects();
        objects.forEach((obj) => {
          obj.scaleX *= scaleX;
          obj.scaleY *= scaleX;
          obj.left *= scaleX;
          obj.top *= scaleX;
          obj.setCoords();
        });

        const backgroundImage = cavasObj.backgroundImage;
        if (backgroundImage) {
          backgroundImage.scaleX *= scaleX;
          backgroundImage.scaleY *= scaleX;
        }

        cavasObj.discardActiveObject();
        cavasObj.setWidth(canvasWidth);
        cavasObj.setHeight(canvasHeight);
        cavasObj.renderAll();
        cavasObj.calcOffset();
      }
    }
  };

  return (
    <div className="canvas-area">
      {canvasObjects.length > 0 &&
        canvasObjects.map((__, index) => (
          <div
            key={index}
            style={{ display: index + 1 === currentPage ? "block" : "none" }}
          >
            <canvas id={`canvas-${index + 1}`} width={500} height={500} />
          </div>
        ))}
      <ProductMargin
        Canvaseditor={currentPageObject}
        Productmargin={Productmargin}
        safeMargin={safeMargin}
        cutMargin={cutMargin}
      />
    </div>
  );
}
