import React from 'react';

export function FontSize(editor) {
  const { Canvaseditor } = editor;
  // Size Picker.
  const handleFontSizeChange = (event) => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('fontSize', event.target.value);
      Canvaseditor.renderAll();
    }
  };
  return (
    <div className="font-size-container">
      <select
        style={{ padding: '7px 3px 4px 12px' }}
        id="text-font-size"
        onChange={handleFontSizeChange}
      >
        <option value="">Select Size</option>
        {(() => {
          const options = [];
          for (let i = 1; i <= 120; i++) {
            options.push(
              <option key={i} value={i}>
                {i}
              </option>
            );
          }
          return options;
        })()}
      </select>
    </div>
  );
}
