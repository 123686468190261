export const defaultFontfamily = [
  {
    id: '1',
    fill: 'black',
    font: 'Roboto',
    size: '40',
    text: 'Header',
    buttonSize: '25px'
  },
  {
    id: '2',
    fill: 'black',
    font: 'Roboto',
    size: '30',
    text: 'Header',
    buttonSize: '18px'
  },
  {
    id: '3',
    fill: 'black',
    font: 'Roboto',
    size: '20',
    text: 'Header',
    buttonSize: '14px'
  }
];

export const customFontfamily = [
  {
    id: '4',
    fill: 'black',
    font: 'Rock Salt',
    size: '30',
    text: 'ADVENTURE',
    img: '/img/Rock-Salt.png'
  },

  {
    id: '5',
    fill: 'black',
    font: 'Alata',
    size: '30',
    text: 'MARKETING PROPOSAL',
    img: '/img/Alata.png'
  },

  {
    id: '6',
    fill: 'black',
    font: 'Anton',
    size: '30',
    text: 'SALE',
    img: '/img/Anton.png'
  },

  {
    id: '7',
    fill: 'black',
    font: 'Yeseva One',
    size: '30',
    text: "You're Invited",
    img: '/img/Yeseva-One.png'
  }
];

export const customFontfamilySection2 = [
  {
    id: '8',
    fill: 'black',
    font: 'Bilbo Swash Caps',
    size: '30',
    text: 'Congratulations!',
    img: '/img/Bilbo-Swash-Caps.png'
  },

  {
    id: '9',
    fill: 'black',
    font: 'Oswald',
    size: '30',
    text: 'OPERATIONS MANAGER',
    img: '/img/Oswald.png'
  },

  {
    id: '10',
    fill: 'black',
    font: 'Raleway',
    size: '30',
    text: 'MINIMALISIM',
    img: '/img/Raleway.png'
  },

  {
    id: '11',
    fill: 'black',
    font: 'Great Vibes',
    size: '30',
    text: 'Last Forever',
    img: '/img/Great-Vibes.png'
  }
];
